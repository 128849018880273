import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';

// MenuItems fragment comes from the main menu
export const pageQuery = graphql`
  fragment PageMenu on wordpress__PAGE {
    custom {
      menu {
        ...MenuItems
      }
    }
  }
`;

const activeClassName = 'kdfjsdklfjsdlkfgj';

const PageMenu = ({ custom, className }) => {
  if (!custom?.menu?.items) {
    return null;
  }
  return (
    <nav className={className}>
      <Ul>
        {custom.menu.items.map((item) => (
          <li key={item.id}>
            <StyledLink
              activeClassName={activeClassName}
              to={item.url}
            >
              {item.title}
            </StyledLink>
          </li>
        ))}
      </Ul>
    </nav>
  );
};


const StyledLink = styled(Link)`
  display: block;
  font-size: 0.8889rem;
  text-decoration: none;
  color: ${(props) => props.theme.fontColor};
  padding: .5rem 0;
  transition: all 0.2s;
  &.${activeClassName},
  &:hover {
    color: ${(props) => props.theme.primaryColor};
    font-weight: 500;
  }
`;

const Ul = styled.ul`
  margin: 0;
  list-style: none;
  border-left: .2rem solid ${(props) => props.theme.primaryColor};
  padding: 0 0 0 1rem;

`;

export default styled(PageMenu)``;
