import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import Layout from '../../layouts';

import Container from '../../components/container';
import Content from '../../components/content';
import PageMenu from '../../components/page-menu';

// remember Project uses this component, but not this query
export const pageQuery = graphql`
  query Page($id: String) {
    page: wordpressPage(id: { eq: $id }) {
      yoast_meta {
        name
        content
        property
      }
      yoast_title
      yoast_json_ld {
        wordpress__context
        wordpress__graph {
          wordpress__type
          wordpress__id
          name
          description
          width
          height
          inLanguage
          datePublished
          dateModified
          isPartOf {
            wordpress__id
          }
          primaryImageOfPage {
            wordpress__id
          }
          url
        }
      }
      featured_media {
        localFile {
          name
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 350, cropFocus: ENTROPY) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      title
      content
      ...PageMenu
    }
  }
`;

export const PageTemplate = ({
  data,
  AsideComponent = PageMenu,
}) => {
  const { page } = data;
  return (
    <Layout page={page}>
      <Container>
        <StyledBackgroundImage fluid={page?.featured_media?.localFile?.childImageSharp?.fluid} />
        <Wrapper columns={page?.custom?.menu || AsideComponent !== PageMenu}>
          <AsideWrapper>
            <AsideComponent {...page} />
          </AsideWrapper>
          <Content>
            {page.title && (
              <h1>{page.title}</h1>
            )}
            {page.content && (
              <div dangerouslySetInnerHTML={{ __html: page.content }} />
            )}
          </Content>
        </Wrapper>
      </Container>
    </Layout>
  );
};

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 12.78rem;
  margin-left: -${(props) => props.theme.grid.containerGap};
  margin-right: -${(props) => props.theme.grid.containerGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    margin-left: 0;
    margin-right: 0;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    height: 19.44rem;
  }
`;

const AsideWrapper = styled.div`

`;

const Wrapper = styled.div`
  padding: ${(props) => props.theme.sectionSpace} 0;
  ${(props) => props.columns && css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${props.theme.grid.columnGap};
    grid-auto-rows: auto;
    ${AsideWrapper} {
      grid-column: 1 / 5;
      grid-row: 2;
      @media all and (min-width: ${props.theme.breakingpoints.lg}) {
        grid-column: 1 / 2;
        grid-row: 1;
      }
    }
    ${Content} {
      grid-column: 1 / 5;
      grid-row: 1;
      @media all and (min-width: ${props.theme.breakingpoints.lg}) {
        grid-column: 2 / 5;
        grid-row: 1;
      }
    }
  `}
`;

export default PageTemplate;
